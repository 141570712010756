import React from "react";
import { useParams, useNavigate } from "react-router";
import { Container, IconButton, Typography } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
function Viewpage() {
  const id = useParams();
  const navigation = useNavigate();
  return (
    <div>
      <Container>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            onClick={() => {
              navigation(-1);
            }}
          >
            <KeyboardBackspaceOutlinedIcon style={{ color: "black" }} />
          </IconButton>
          <Typography
            style={{
              fontWeight: "700",
              fontFamily: "Arial",
              margin: "3px",
              padding: "2px",
            }}
          >
            View service
          </Typography>
        </span>

      </Container>
    </div>
  );
}

export default Viewpage;
