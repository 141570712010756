import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { v4 as uuid } from "uuid";
// import "flexmonster/flexmonster.css";
import Divider from "@mui/material/Divider";
import toJsonSchema from "to-json-schema";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useNavigate } from "react-router";
import { IconButton, styled } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// const Input = styled("input")({
//   display: "none",
// });

const steps = ["Register", "Request", "Response"];

function Stepform() {
  const unique_id = uuid();

  const navigation = useNavigate();

  //Variables Initialization
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [schema, setSchema] = React.useState("");
  const pivot = React.useRef(null);
  const [alertmsg, setAlertmsg] = React.useState("");
  const [template, setTemplate] = React.useState({
    id: Number(),
    apiname: String(),
    apitype: String(),
    httpmethod: String(),
    url: String(),
    endpointpath: String(),
    apiusage: String(),
  });
  const [apidata, setApidata] = React.useState("");
  const [alertstatus, setAlertStatus] = React.useState("");
  const [readquery, setReadquery] = React.useState("");
  const [fmrender, setFmender] = React.useState([]);
  const [dataload, setDataload] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [resschema, setReschema] = React.useState("");
  const onchangeres = (v) => {
    setApidata(JSON.parse(v));
  };

  //Function for closing snackbar
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    //setActiveStep(0);
    //navigation(-1);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  //Function for Next button
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 1 && readquery.length !== 0 && schema.length !== 0) {
      console.log("final read query", JSON.parse(readquery));
      var config = {
        method: "post",
        url: process.env.REACT_APP_API_URL,
        header: { "Content-Type": "application/json" },
        data: JSON.parse(readquery),
      };
      axios(config)
        .then((res) => {
          console.log("datavalue", res.data.result);
          setApidata({
            result: [res.data.result[0]],
            Code: res.data.Code,
            query: res.data.query,
          });
          var report = {
            dataSource: {
              data: res.data.result,
            },

            options: {
              grid: {
                type: "flat",
                showGrandTotals: "off",
                showTotals: "off",
              },
            },
          };
          if (res.status === 200) {
            console.log("report", report, res.status);
            setDataload(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else if (
      (activeStep === 0 && template.apiname.length === 0) ||
      template.apitype.length === 0 ||
      template.httpmethod.length === 0 ||
      template.url.length === 0 ||
      template.endpointpath.length === 0 ||
      template.apiusage.length === 0
    ) {
      // console.log("called2");
      setOpen(true);
      setAlertStatus("warning");
      setAlertmsg("Please enter the details");
    } else if (
      (readquery.length === 0 && activeStep === 1) ||
      (schema.length === 0 && activeStep === 1)
    ) {
      // console.log("called3");
      if (schema.length === 0) {
        setOpen(true);
        setAlertStatus("warning");
        setAlertmsg("Please press the generate button");
      } else {
        setOpen(true);
        setAlertStatus("warning");
        setAlertmsg("Please enter the details");
      }
    } else {
      // console.log("called4");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    setDataload(true);
  };

  //Function for back button
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //Function for handeling Reset
  const handleReset = () => {
    setActiveStep(0);
  };

  //Function for finish button
  const handleFinish = () => {
    var datas = [
      {
        db_name: process.env.REACT_APP_DB_NAME,
        entity: process.env.REACT_APP_ENTITY,
        is_metadata: true,
        metadataId: "aa29f2d7-f02e-4125-a73b-f6b9fdc9573d",
        metadata_dbname: process.env.REACT_APP_DB_NAME,
        doc: {
          id: template.id,
          apiname: template.apiname,
          apitype: template.apitype,
          url: template.url,
          endpointpath: template.endpointpath,
          httpmethod: template.httpmethod,
          request: JSON.parse(readquery),
          requestschema: JSON.parse(schema),
          response: apidata,
          responseschema: JSON.parse(resschema),
          clientid: sessionStorage.getItem("clientid"),
          projectid: sessionStorage.getItem("projectid"),
          metadataid: sessionStorage.getItem("metadataId"),
          apiusage: template.apiusage,
        },
      },
    ];
    console.log("data to be sent to backend", datas);
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL_UPSERT,
      header: { "Content-Type": "application/json" },
      data: datas,
    };
    axios(config)
      .then((res) => {
        if (res.status === 200) {
          setOpen(true);
          setAlertStatus("success");
          setAlertmsg(
            "Data is added Successfully ,click Home to view main page"
          );
        }
      })
      .catch((err) => {
        console.error(err);
        setOpen(true);
        setAlertStatus("error");
        setAlertmsg("Oops Something went wrong");
      });
    // console.log("called ");
    setReadquery("");
    setSchema("");
    setTemplate({
      id: Number(),
      apiname: String(),
    });

    setFmender([]);
    console.log("show", localStorage.getItem("url"));
    localStorage.setItem("reload", "true");
  };

  //Funciton for json format
  const converter = () => {
    var parsed = JSON.parse(readquery);
    const schemas = toJsonSchema(parsed);
    var pretty = JSON.stringify(schemas, undefined, 4);
    var pretty1 = JSON.stringify(parsed, undefined, 4);
    setSchema(pretty);
    setReadquery(pretty1);
    // console.log(schemas);
  };
  const converter2 = () => {
    console.log(apidata);

    const schemas = toJsonSchema(apidata);
    var pretty = JSON.stringify(schemas, undefined, 4);
    setReschema(pretty);

    // console.log(schemas);
  };

  return (
    <Box sx={{ width: "95%", margin: "auto" }}>
      <div>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <IconButton
            onClick={() => {
              navigation(-1);
            }}
          >
            <KeyboardBackspaceOutlinedIcon style={{ color: "black" }} />
            <h6>Home</h6>
          </IconButton>
        </span>
      </div>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {activeStep + 1 === 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  width: "300px",
                  margin: "50px auto",
                }}
              >
                {/* <label style={{ margin: "10px 0" }}>ID </label>
                <input
                  
                  disabled
                  style={{ padding: "10px" }}
                  value={template.id}
                  onChange={(e) =>
                    setTemplate({ ...template, id: e.target.value })
                  }
                ></input> */}
                <label style={{ padding: "10px" }}>
                  API Name<span style={{ color: "red" }}>*</span>
                </label>

                <input
                  required
                  style={{ padding: "10px" }}
                  value={template.apiname}
                  onChange={(e) =>
                    setTemplate({ ...template, apiname: e.target.value })
                  }
                ></input>
                <label style={{ padding: "10px" }}>
                  API Type<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  required
                  style={{ padding: "10px" }}
                  placeholder="Select"
                  value={template.apitype}
                  onChange={(e) =>
                    setTemplate({ ...template, apitype: e.target.value })
                  }
                >
                  <option>select</option>
                  {/* <option value={"FM/JPER"}>FM/JPER</option> */}
                  <option value={"Arango"}>Arango</option>
                  <option value={"Http"}>Http</option>
                </select>
                <label style={{ padding: "10px" }}>
                  Http Method Type<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  required
                  style={{ padding: "10px" }}
                  placeholder="Select"
                  value={template.httpmethod}
                  onChange={(e) =>
                    setTemplate({ ...template, httpmethod: e.target.value })
                  }
                >
                  <option>select</option>
                  {/* <option value={"FM/JPER"}>FM/JPER</option> */}
                  <option value={"Get"}>Get</option>
                  <option value={"Post"}>post</option>
                </select>
                <label style={{ padding: "10px" }}>
                  URL<span style={{ color: "red" }}>*</span>
                </label>

                <input
                  required
                  style={{ padding: "10px" }}
                  value={template.url}
                  onChange={(e) =>
                    setTemplate({ ...template, url: e.target.value })
                  }
                ></input>

                <label style={{ padding: "10px" }}>
                  End point Path<span style={{ color: "red" }}>*</span>
                </label>

                <input
                  required
                  style={{ padding: "10px" }}
                  value={template.endpointpath}
                  onChange={(e) =>
                    setTemplate({ ...template, endpointpath: e.target.value })
                  }
                ></input>
                <label style={{ padding: "10px" }}>
                  API Usage<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  required
                  style={{ padding: "10px" }}
                  placeholder="Select"
                  value={template.apiusage}
                  onChange={(e) =>
                    setTemplate({ ...template, apiusage: e.target.value })
                  }
                >
                  <option>select</option>
                  {/* <option value={"FM/JPER"}>FM/JPER</option> */}
                  <option value={"Global"}>Global</option>
                  <option value={"Project"}>Project</option>
                </select>
              </div>
            )}
            {activeStep + 1 === 2 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>API REQUEST</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={readquery}
                      onChange={(e) => {
                        setReadquery(e.target.value);
                      }}
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 30px",
                    }}
                  >
                    <button onClick={() => converter()}>Generate</button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>REQUEST SCHEMA</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={schema}
                      onChange={(e) => setSchema(e.target.value)}
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>
                </div>
              </div>
            )}
            {/* {activeStep + 1 === 3 && <div>Form3</div>} */}
            {activeStep + 1 === 3 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>API RESPONSE</label>
                    <textarea
                      style={{ padding: "10px" }}
                      value={JSON.stringify(apidata, undefined, 4)}
                      onChange={(e) => {
                        onchangeres(e.target.value);
                      }}
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 30px",
                    }}
                  >
                    <button onClick={() => converter2()}>Generate</button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <label>RESPONSE SCHEMA</label>

                    <textarea
                      style={{ padding: "10px" }}
                      value={resschema}
                      onChange={(e) => setReschema(e.target.value)}
                      rows="20"
                      cols="40"
                    ></textarea>
                  </div>
                </div>
              </div>
            )}
            <Divider style={{ margin: "10px 0" }} variant="middle" />
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignContent: "flex-end",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={() => handleBack()}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            {activeStep === steps.length - 1 ? (
              <Button onClick={() => handleFinish()}>Finish</Button>
            ) : (
              <Button onClick={() => handleNext()}>Next</Button>
            )}
          </Box>
        </React.Fragment>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {alertmsg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
export default Stepform;
