import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Divider from "@mui/material/Divider";
import toJsonSchema from "to-json-schema";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { IconButton, styled } from "@mui/material";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Input = styled("input")({
  display: "none",
});
const steps = ["Register", "Request", "Response"];

function Stepform() {
  const navigation = useNavigate();
  const location = useLocation();
  // console.log("loc", location.state);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [schema, setSchema] = React.useState(location?.state.readjsonschema);
  const pivot = React.useRef(null);
  const [alertmsg, setAlertmsg] = React.useState("");
  const [template, setTemplate] = React.useState({
    id: String(location?.state.id),
    apiname: String(location?.state.apiname),
    apitype: String(location?.state.apitype),
    httpmethod: String(location?.state.httpmethod),
    url: String(location?.state.url),
    endpointpath: String(location?.state.endpointpath),
    apiusage: String(location?.state.apiusage),
  });
  const [alertstatus, setAlertStatus] = React.useState("");
  const [readquery, setReadquery] = React.useState(location?.state.request);
  const [open, setOpen] = React.useState(false);
  const [headername, setHeadername] = React.useState("");
  const [fmheadfoot, setFmheadfoot] = React.useState(location.state.fmheadfoot);
  const [dataload, setDataload] = React.useState(true);
  const [apidata, setApidata] = React.useState("");
  const [fileid, setFileid] = React.useState("");
  const [fmrender, setFmender] = React.useState([]);
  const [resschema, setReschema] = React.useState("");

  console.log("location", location.state);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    // setActiveStep(0);
    // navigation(-1);
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  /////Next button function////

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 1 && readquery.length !== 0 && schema.length !== 0) {
      // console.log("final read query", temp);
      var config = {
        method: "post",
        url: process.env.REACT_APP_API_URL,
        header: { "Content-Type": "application/json" },
        data: readquery,
      };
      axios(config)
        .then((res) => {
          console.log("datavalue", res.data);
          setApidata({
            result: [res.data.result[0]],
            Code: res.data.Code,
            query: res.data.query,
          });
          var report = {
            dataSource: {
              data: res.data.result,
            },

            options: {
              grid: {
                type: "flat",
                showGrandTotals: "off",
                showTotals: "off",
              },
            },
          };
          if (res.status === 200) {
            console.log("report", report, res.status);
            setDataload(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else if (activeStep === 0 && template.apiname.length === 0) {
      // console.log("called2");
      setOpen(true);
      setAlertStatus("warning");
      setAlertmsg("Please enter the details");
    } else {
      // console.log("called4");
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    setDataload(true);
  };

  ///Back button function///

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /////Finish button function///
  const handleFinish = () => {
    console.log();
    var edithead;
    if (template.apitype === location.state.apitype) {
      edithead = fmheadfoot;
    } else {
      var headerchange = location.state.fmheadfoot.header.split("<h1");
      var headjoin = `<h1 style='width:80%;font-size:50px;text-align:center;'>${template.apiname}</h1></div>`;
      var headresult = headerchange.concat(headjoin);
      // console.log(headresult);
      edithead = {
        header: headresult,
        footer: location.state.fmheadfoot.footer,
        pageOrientation: location.state.fmheadfoot.pageOrientation,
      };
    }

    var sendtobackend;
    if (template.apitype === "Arango") {
      sendtobackend = {
        db_name: process.env.REACT_APP_DB_NAME,
        entity: process.env.REACT_APP_ENTITY,
        is_metadata: false,
        filter: { _key: location.state._key },
        doc: {
          id: template.id,
          apiname: template.apiname,
          httpmethod: template.httpmethod,
          apiusage: template.apiusage,
          endpointpath: template.endpointpath,
          readquery: readquery,
          url: template.url,
          readjsonschema: schema,
          apitype: template.apitype,
          clientid: sessionStorage.getItem("clientid"),
          metadataid: sessionStorage.getItem("metadataId"),
          projectid: sessionStorage.getItem("projectid"),
        },
      };
    } else {
      var temp = {};
      temp = pivot.current.flexmonster.getReport({
        withGlobals: true,
      });

      // console.log("function called", result);
      sendtobackend = {
        db_name: process.env.REACT_APP_DB_NAME,
        entity: process.env.REACT_APP_ENTITY,
        is_metadata: true,
        metadataId: "aa29f2d7-f02e-4125-a73b-f6b9fdc9573d",
        metadata_dbname: process.env.REACT_APP_DB_NAME,
        filter: { _key: location.state._key },
        doc: {
          id: template.id,
          apiname: template.apiname,
          apitype: template.apitype,
          url: template.url,
          endpointpath: template.endpointpath,
          httpmethod: template.httpmethod,
          request: JSON.parse(readquery),
          requestschema: JSON.parse(schema),
          response: apidata,
          responseschema: JSON.parse(resschema),
          clientid: sessionStorage.getItem("clientid"),
          projectid: sessionStorage.getItem("projectid"),
          metadataid: sessionStorage.getItem("metadataId"),
          apiusage: template.apiusage,
        },
      };
    }
    var datas = [sendtobackend];

    console.log("data to be sent to backend", datas);
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_URL_UPSERT,
      header: { "Content-Type": "application/json" },
      data: datas,
    };
    axios(config)
      .then((res) => {
        // console.log(res.status);
        if (res.status === 200) {
          setOpen(true);
          setAlertStatus("success");
          setAlertmsg("Data is edited Successfully");
        }
      })
      .catch((err) => {
        console.error(err);
        setOpen(true);
        setAlertStatus("error");
        setAlertmsg("Oops Something went wrong");
      });
    // console.log("called ");
    setReadquery("");
    setSchema("");
    setTemplate({
      id: Number(),
      apiname: String(),
    });
    // setActiveStep(0);
    console.log("show", localStorage.getItem("url"));
    localStorage.setItem("reload", "true");
  };

  ////JSON formatter function///
  const converter = () => {
    var parsed = readquery;
    const schemas = toJsonSchema(parsed);
    var pretty = JSON.stringify(schemas, undefined, 4);
    //var pretty1 = JSON.stringify(parsed, undefined, 4);
    setSchema(pretty);
    setReadquery(readquery);
  };

  const converter2 = () => {
    console.log(JSON.apidata);

    const schemas = toJsonSchema(apidata);
    var pretty = JSON.stringify(schemas, undefined, 4);
    setReschema(pretty);

    // console.log(schemas);
  };
  return (
    <Box sx={{ width: "95%", margin: "auto" }}>
      <IconButton
        onClick={() => {
          navigation(-1);
        }}
      >
        <KeyboardBackspaceOutlinedIcon style={{ color: "black" }} />
      </IconButton>
      <h2 style={{ textAlign: "center" }}>Service Generator</h2>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1 }}>
          {activeStep + 1 === 1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
                width: "300px",
                margin: "50px auto",
              }}
            >
              {/* <label style={{ margin: "10px 0" }}>
                 Id <span style={{ color: "red" }}>*</span>
              </label>
              <input
                disabled
                style={{ padding: "10px" }}
                value={template.apiId}
                onChange={(e) =>
                  setTemplate({ ...template, id: e.target.value })
                }
              ></input> */}
              <label style={{ padding: "10px" }}>
                API Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                required
                style={{ padding: "10px" }}
                value={template.apiname}
                onChange={(e) =>
                  setTemplate({ ...template, apiname: e.target.value })
                }
              ></input>
              <label style={{ padding: "10px" }}>
                API Type <span style={{ color: "red" }}>*</span>
              </label>
              <select
                required
                style={{ padding: "10px" }}
                value={template.apitype}
                placeholder="Select"
                onChange={(e) =>
                  setTemplate({ ...template, apitype: e.target.value })
                }
              >
                <option value={""}>select</option>
                <option value={"Arango"}>Arango</option>
                <option value={"Http"}>Http</option>
              </select>

              <label style={{ padding: "10px" }}>
                Http Method Type <span style={{ color: "red" }}>*</span>
              </label>
              <select
                required
                style={{ padding: "10px" }}
                value={template.httpmethod}
                placeholder="Select"
                onChange={(e) =>
                  setTemplate({ ...template, httpmethod: e.target.value })
                }
              >
                <option value={""}>select</option>
                <option value={"Get"}>Get</option>
                <option value={"Post"}>Post</option>
              </select>

              <label style={{ padding: "10px" }}>
                URL<span style={{ color: "red" }}>*</span>
              </label>

              <input
                required
                style={{ padding: "10px" }}
                value={template.url}
                onChange={(e) =>
                  setTemplate({ ...template, url: e.target.value })
                }
              ></input>

              <label style={{ padding: "10px" }}>
                End point Path<span style={{ color: "red" }}>*</span>
              </label>

              <input
                required
                style={{ padding: "10px" }}
                value={template.endpointpath}
                onChange={(e) =>
                  setTemplate({ ...template, endpointpath: e.target.value })
                }
              ></input>

              <label style={{ padding: "10px" }}>
                API Usage<span style={{ color: "red" }}>*</span>
              </label>
              <select
                required
                style={{ padding: "10px" }}
                placeholder="Select"
                value={template.apiusage}
                onChange={(e) =>
                  setTemplate({ ...template, apiusage: e.target.value })
                }
              >
                <option>select</option>
                <option value={"Global"}>Global</option>
                <option value={"Project"}>Project</option>
              </select>
            </div>
          )}
          {activeStep + 1 === 2 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label>API REQUEST</label>
                  <textarea
                    style={{ padding: "10px" }}
                    value={JSON.stringify(readquery, undefined, 4)}
                    onChange={(e) => {
                      setReadquery(e.target.value);
                    }}
                    rows="20"
                    cols="40"
                  ></textarea>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 30px",
                  }}
                >
                  <button onClick={() => converter()}>Generate</button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label>REQUEST SCHEMA</label>
                  <textarea
                    style={{ padding: "10px" }}
                    value={schema}
                    onChange={(e) => setSchema(e.target.value)}
                    rows="20"
                    cols="40"
                  ></textarea>
                </div>
              </div>
            </div>
          )}
          {activeStep + 1 === 3 && (
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label>API RESPONSE</label>
                  <textarea
                    style={{ padding: "10px" }}
                    value={JSON.stringify(apidata, undefined, 4)}
                    onChange={(e) => {
                      setReschema(e.target.value);
                    }}
                    rows="20"
                    cols="40"
                  ></textarea>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 30px",
                  }}
                >
                  <button onClick={() => converter2()}>Generate</button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <label>RESPONSE SCHEMA</label>

                  <textarea
                    style={{ padding: "10px" }}
                    value={resschema}
                    onChange={(e) => setReschema(e.target.value)}
                    rows="20"
                    cols="40"
                  ></textarea>
                </div>
              </div>
            </div>
          )}

          <Divider style={{ margin: "10px 0" }} variant="middle" />
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
          }}
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={() => handleBack()}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === steps.length - 1 ? (
            <Button onClick={() => handleFinish()}>Finish</Button>
          ) : (
            <Button onClick={() => handleNext()}>Next</Button>
          )}
        </Box>
      </React.Fragment>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alertstatus}
          sx={{ width: "100%" }}
        >
          {alertmsg}

      
        </Alert>
      </Snackbar>
    </Box>
  );
}
export default Stepform;